import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';
import { error } from 'util';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        }
        return this.user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     * `/api/login`
     */
    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    this.user = user;
                    // store user details and jwt in cookie
                    this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
                }
                return user;
            }));
    }

    /**
     * 
     * @param email email of user
     * @param password password of user
     * @param firstname first name of user 
     * @param UserName user name 
     * @param PhoneNumber phone number
     */
    register(email: string, password: string, firstname: string, username: string, phonenumber: string, keyAccess: string) {
        return this.http.post<any>(`${environment.apiUrl}/users`, { email, password, firstname, username, phonenumber, keyAccess })
            .pipe(map(user => {
                if (user && user.token) {
                    this.user = user;
                    this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
                }
                return user;
            }
            ));
    }

    /**
     * Performs the auth
     * @param email email of user
     */
     forgotPassword(email: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/forgot-password`, { email })
            .pipe(map(result => {
                return result;
            }));
    }

    /**
     * Update Profile
     */
    updateProfile(UserName: string, FirstName: string, LastName: string, PhoneNumber: string, fileToUpload: File) {
        const formData: FormData = new FormData();
        formData.append('Image', fileToUpload, fileToUpload.name);
        formData.append('UserName', UserName);
        formData.append('FirstName', FirstName);
        formData.append('LastName', LastName);
        formData.append('PhoneNumber', PhoneNumber);

        return this.http.post<any>(`${environment.apiUrl}/users/update`, formData)
            .pipe(map(_result => {
                if (_result.user) {
                    this.user = _result.user;
                    // TODO: Set Correct values
                    // this.cookieService.setCookie('currentUser', JSON.stringify(_result.user), 1);
                }
                return _result;
            }
            ));
    }

    /**
     * Change the password
     * @param email 
     * @param password
     */
     changePassword(email: string, password: string, token: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/reset-password`, { email, password, token })
            .pipe(map(_result => {
                return _result;
            }));
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.user = null;
    }
}

